* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
	font-weight: 400;
}

img, svg {
	display: block;
	max-width: 100%;
	height: auto;
}

body {
	font-family: "Gabarito", sans-serif;
	font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue);
  overflow-x: hidden;
}

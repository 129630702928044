* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  font-weight: 400;
}

img, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  font-family: "Gabarito", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue);
  overflow-x: hidden;
}

:root {
  --blue: #293783;
  --lightgrey: #E2E2E2;
  --darkgrey: #808080;
  --white: #FFFFFF;
  --yellow: #FFFF00;
  --lavendar: #7C8FEE;
  --h1: 1.5rem;
  --title: 1.3rem;
  --body: 1.125rem;
  --lineHeight: 1.4;
  --sideRail: 2rem;
  --maxWidth: 640px;
}
@media screen and (min-width: 960px) {
  :root {
    --h1: 3.125rem;
    --title: 1.5rem;
    --sideRail: 3rem;
    --maxWidth: 1096px;
  }
}

.bg-blue {
  background: var(--blue);
  color: var(--white);
}

.bg-lightgrey {
  background: var(--lightgrey);
}

.df {
  display: flex;
}

.db {
  display: block;
}

.fc {
  flex-direction: column;
}

.ec > div {
  flex: 1;
}

.jc {
  justify-content: center;
}

.ja {
  justify-content: space-around;
}

.jb {
  justify-content: space-between;
}

.js {
  justify-content: flex-start;
}

.je {
  justify-content: flex-end;
}

.w100 {
  width: 100%;
}

.ac {
  align-items: center;
}

.as {
  align-items: flex-start;
}

.ae {
  align-items: flex-end;
}

.cp {
  cursor: pointer;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--maxWidth);
  padding-left: var(--sideRail);
  padding-right: var(--sideRail);
}

.height-minus-frame {
  min-height: calc(100vh - 490px);
}

.mb-2r {
  margin-bottom: 2rem;
}

.mb-3r {
  margin-bottom: 3rem;
}

.mb-4r {
  margin-bottom: 4rem;
}

.mb-5r {
  margin-bottom: 5rem;
}

.mb-6r {
  margin-bottom: 6rem;
}

.mb-8r {
  margin-bottom: 8rem;
}

.mb-9r {
  margin-bottom: 9rem;
}

.pt-2r {
  padding-top: 2rem;
}

.pt-3r {
  padding-top: 3rem;
}

.pt-4r {
  padding-top: 4rem;
}

.vert-spacer {
  margin-bottom: 3rem;
}
@media screen and (max-width: 960px) {
  .vert-spacer.mobile-tall {
    margin-bottom: 5rem;
  }
}
@media screen and (min-width: 960px) {
  .vert-spacer {
    margin-bottom: 6rem;
  }
}
.vert-spacer.taller {
  margin-bottom: 4rem;
}
@media screen and (min-width: 960px) {
  .vert-spacer.taller {
    margin-bottom: 9rem;
  }
}

header {
  padding: 1.5rem 0 1.5rem;
  background: var(--blue);
  color: var(--white);
}
@media screen and (min-width: 960px) {
  header {
    padding: 2.5rem 0;
  }
}
@media screen and (max-width: 960px) {
  header {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3;
  }
}
header .header-nav {
  display: inline-block;
}
header .header-nav a {
  text-decoration: none;
  font-weight: 400;
  color: var(--white);
  font-size: var(--body);
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
@media screen and (min-width: 960px) {
  header .header-nav a:before {
    display: block;
    content: "";
    background: var(--white);
    height: 4px;
    width: auto;
    position: absolute;
    bottom: -0.8rem;
    left: 50%;
    right: 50%;
    transition: all 0.1s ease-out;
  }
}
header .header-nav a:hover:before {
  left: 0;
  right: 0;
}
body.about header .header-nav a.about, body.projects header .header-nav a.projects {
  color: var(--yellow);
  position: relative;
}
@media screen and (min-width: 960px) {
  body.about header .header-nav a.about:before, body.projects header .header-nav a.projects:before {
    display: block;
    content: "";
    background: var(--yellow);
    height: 4px;
    width: auto;
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    right: 0;
  }
}
@media screen and (min-width: 960px) {
  header .header-nav a:not(:last-child) {
    margin-right: 3rem;
  }
}
@media screen and (max-width: 960px) {
  header .header-nav {
    top: 0;
    display: flex;
    position: fixed;
    right: -100vw;
    width: 100%;
    height: 100%;
    background: var(--blue);
    z-index: 2000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
  }
  header .header-nav a {
    font-size: var(--title);
    padding: 1rem;
  }
  header .header-nav .mobile-mask {
    position: absolute;
    top: 0;
    height: 96px;
    width: 100%;
    left: 0;
    background-color: var(--yellow);
    overflow: hidden;
    z-index: 2002;
    clip-path: inset(0);
  }
  header .header-nav .mobile-mask img {
    position: fixed;
    width: 60px;
    top: 24px;
    left: 32px;
  }
  .mobile-open header .header-nav {
    right: 0;
  }
}
header .header-logo {
  position: relative;
}
@media screen and (max-width: 960px) {
  header .header-logo {
    width: 60px;
  }
}
header .mobile-menu {
  width: 72px;
  height: 72px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 0px solid white;
  background: transparent;
  margin-right: -24px;
  margin-top: -12px;
  cursor: pointer;
  z-index: 2002;
  position: relative;
  border: 0px solid yellow;
  -webkit-tap-highlight-color: transparent;
}
@media screen and (min-width: 960px) {
  header .mobile-menu {
    display: none;
  }
}
header .mobile-menu span {
  background: var(--white);
  width: 24px;
  height: 2px;
  position: absolute;
  transition: all 0.2s ease-in-out;
}
header .mobile-menu span:nth-child(1) {
  top: 28px;
}
header .mobile-menu span:nth-child(3) {
  bottom: 28px;
}
header .mobile-menu span:nth-child(4), header .mobile-menu span:nth-child(5) {
  width: 0;
}
header .mobile-menu span:nth-child(4) {
  transform: rotate(-45deg);
}
header .mobile-menu span:nth-child(5) {
  transform: rotate(45deg);
}
.mobile-open header .mobile-menu span {
  background: var(--blue);
}
.mobile-open header .mobile-menu span:nth-child(1), .mobile-open header .mobile-menu span:nth-child(2), .mobile-open header .mobile-menu span:nth-child(3) {
  width: 0px;
}
.mobile-open header .mobile-menu span:nth-child(4), .mobile-open header .mobile-menu span:nth-child(5) {
  width: 24px;
}

footer {
  padding: 1.5rem 0;
  background: var(--lightgrey);
  color: var(--blue);
  text-align: center;
  position: relative;
}
footer .footer-verbiage {
  padding: 0.7rem 0 2rem;
  position: relative;
}
@media screen and (min-width: 960px) {
  footer .footer-verbiage {
    padding: 1.125rem 0 2rem;
  }
}
footer .footer-verbiage > p {
  font-size: var(--title);
}
@media screen and (max-width: 960px) {
  footer .footer-logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 960px) {
  footer .footer-logo {
    position: absolute;
    top: 0;
    left: -30px;
  }
}
footer .footer-cta {
  padding-bottom: 2.4rem;
}
footer .bottom-line {
  padding-top: 1.5rem;
  border-top: 4px solid var(--white);
  font-size: 0.9rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  footer .bottom-line {
    text-align: center;
    flex-direction: column;
  }
}
footer .bottom-line a {
  text-decoration: none;
  position: relative;
}
footer .bottom-line a:before {
  display: block;
  content: "";
  height: 100%;
  position: absolute;
  width: 0%;
  top: 0;
  right: 0;
  background: var(--yellow);
  transition: width 0.15s ease-out;
  z-index: 0;
}
footer .bottom-line a span {
  display: inline-block;
  position: relative;
  z-index: 1;
}
footer .bottom-line a:hover:before {
  width: 100%;
}

.btn {
  display: inline-flex;
  border: 2px solid var(--blue);
  color: var(--blue);
  padding: 0.8rem 3rem 0.8rem 1.2rem;
  border-radius: 0.15rem;
  text-decoration: none;
  font-size: var(--body);
  position: relative;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
}
.btn.dark.hand {
  background: var(--blue);
  padding-right: 3.4rem;
}
.btn.dark.hand:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../icons/hand.svg") no-repeat calc(100% - 0.4rem) calc(50% - 2px);
}
@media screen and (hover: hover) and (min-width: 960px) {
  .btn.dark.hand:hover {
    color: var(--blue);
  }
  .btn.dark.hand:hover:after {
    background-image: url("../icons/hand-rev.svg");
    background-position: 100% calc(50% - 2px);
    background-size: contain;
    transition: all 0.75s ease-out;
  }
}
.btn:not(.hand):after {
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 1rem;
  content: "";
  width: 18px;
  height: 16px;
  background: url("../icons/arrow-right.svg") no-repeat 50% 50%;
}
.btn:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 100%;
  bottom: 50%;
  content: "";
  width: auto;
  height: auto;
  background: var(--yellow);
  z-index: -1;
  transition: all 0.2s ease-in-out;
}
@media screen and (hover: hover) and (min-width: 960px) {
  .btn:not([data-no-rollover], .ball):hover:before {
    right: 0;
    top: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 960px) {
  [data-horz-ctas] .btn, .about-push [data-horz-ctas] .btn {
    margin-bottom: 0;
  }
}
[data-horz-ctas] .btn:not(:last-child), .about-push [data-horz-ctas] .btn:not(:last-child) {
  margin-right: 1rem;
}
@media screen and (max-width: 960px) {
  [data-horz-ctas] .btn:not(:last-child), .about-push [data-horz-ctas] .btn:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.btn.dark {
  background-color: var(--blue);
  color: var(--yellow);
  background: var(--blue);
}
.btn.rev {
  border-color: var(--white);
  color: var(--white);
  background: url("../icons/arrow-right-w.svg") no-repeat calc(100% - 1rem) 50%;
}
.btn.rev:after {
  background: url("../icons/arrow-right-w.svg") no-repeat 50% 50%;
}
@media screen and (hover: hover) and (min-width: 960px) {
  .btn.rev:hover {
    border-color: var(--yellow);
    color: var(--blue);
  }
  .btn.rev:hover:after {
    background: url("../icons/arrow-right.svg") no-repeat 50% 50%;
  }
}
.btn.ball {
  border: none;
  background: transparent;
}
@media (hover: hover) {
  .btn.ball span {
    width: 32px;
    right: 0;
  }
}
.btn.ball:after {
  background-color: var(--yellow);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: calc(50% - 16px);
  right: 0;
}
@media screen and (hover: hover) and (min-width: 960px) {
  a.home-featured-project--frame:hover .btn.ball:after, .btn.ball:hover:after {
    background: var(--blue) url("../icons/arrow-right-y.svg") no-repeat 50% 50%;
  }
}

.icon-ball {
  width: 52px;
  height: 52px;
  background: var(--blue);
  border-radius: 50%;
}
.icon-ball.yellow.quote {
  background: var(--yellow) url("../icons/quote-b.svg") 50% 50% no-repeat;
  background-size: 57px 40px;
}
@media screen and (max-width: 960px) {
  .icon-ball.yellow.quote {
    background-size: 28.5px 20px;
  }
}
.icon-ball.quote {
  background: var(--blue) url("../icons/quote.svg") 50% 50% no-repeat;
  background-size: 57px 40px;
}
@media screen and (max-width: 960px) {
  .icon-ball.quote {
    background-size: 28.5px 20px;
  }
}
.icon-ball.list {
  background: var(--blue) url("../icons/list.svg") 50% 50% no-repeat;
  background-size: contain;
}
.icon-ball.tools {
  background: var(--blue) url("../icons/tools.svg") 50% 50% no-repeat;
  background-size: contain;
}
@media screen and (max-width: 960px) {
  .icon-ball {
    background-size: 50% 50%;
  }
}
@media screen and (min-width: 960px) {
  .icon-ball {
    width: 104px;
    height: 104px;
  }
}

.line-head {
  margin-bottom: 2rem;
  position: relative;
}
@media screen and (min-width: 960px) {
  .line-head {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 960px) {
  .line-head.compact {
    margin-bottom: 2rem;
  }
}
.line-head.compact + .project-section {
  margin-bottom: 6rem;
}
.line-head.compact.bm-condensed + .project-section {
  margin-bottom: 4rem;
}
.line-head span {
  background: var(--white);
  padding-right: 2rem;
  position: relative;
  display: inline-block;
  width: auto;
  white-space: collapse;
}
.line-head.right {
  text-align: right;
}
.line-head.right span {
  padding-left: 2rem;
  padding-right: 0;
}
.line-head:before {
  display: block;
  content: "";
  width: 100%;
  height: 4px;
  background: var(--lightgrey);
  position: absolute;
  top: calc(50% - 2px);
}
.line-head.dark:before {
  background: var(--blue);
}

.ball-box, .ball-col {
  position: relative;
}
.ball-box:not(.project-section), .ball-col:not(.project-section) {
  margin-bottom: 6rem;
}
@media screen and (min-width: 960px) {
  .ball-box:not(.project-section), .ball-col:not(.project-section) {
    margin-bottom: 12rem;
  }
}

@media screen and (max-width: 960px) {
  .ball-box .icon-ball {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .ball-box {
    padding: 6rem;
    border: 4px solid var(--blue);
    position: relative;
  }
  .ball-box p:not(.attribution) {
    font-size: var(--title);
    margin-bottom: 2rem;
  }
  .ball-box .icon-ball {
    position: absolute;
    right: -10px;
    bottom: -16px;
    z-index: 2;
  }
  .ball-box .mask {
    display: block;
    background: #fff;
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 146px;
    height: 114px;
    z-index: 1;
  }
}

.ball-col {
  margin-bottom: 4rem;
  font-size: var(--title);
}
@media screen and (min-width: 960px) {
  .ball-col {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 960px) {
  .ball-col[data-mb-0] {
    margin-bottom: 0;
  }
}
.ball-col[data-pb-0] {
  padding-bottom: 0;
}
@media screen and (max-width: 960px) {
  .ball-col {
    padding-top: 84px;
  }
}
@media screen and (min-width: 960px) {
  .ball-col {
    padding-right: 12rem;
    padding-bottom: 6rem;
  }
}
@media screen and (min-width: 960px) {
  .ball-col.narrow-right {
    padding-right: 4rem;
  }
}
.ball-col h2 {
  font-size: var(--h1);
  max-width: 80%;
  margin-bottom: 2rem;
}
@media screen and (min-width: 960px) {
  .ball-col h2 {
    margin-bottom: 5rem;
  }
}
.ball-col .experience-set p:first-child {
  margin-bottom: 0.4rem;
}
.ball-col .experience-set ul {
  margin-bottom: 2rem;
}
.ball-col .icon-ball {
  position: absolute;
  top: 0;
}
@media screen and (max-width: 960px) {
  .ball-col .icon-ball {
    left: 0;
  }
}
@media screen and (min-width: 960px) {
  .ball-col .icon-ball {
    right: -52px;
  }
  .ball-col .icon-ball + em, .ball-col .icon-ball + em + em {
    position: absolute;
    background: var(--blue);
  }
  .ball-col .icon-ball + em {
    width: 4px;
    right: -2px;
    top: 138px;
    bottom: 88px;
  }
  .ball-col .icon-ball + em + em {
    height: 4px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ball-col .icon-ball + em + em.shorter {
    right: 290px;
  }
}
.ball-col p:first-child {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 960px) {
  .ball-col .bottom-cta {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    padding-left: 2.5rem;
  }
}
.ball-col .experience-rack {
  margin-bottom: 0;
}
@media screen and (min-width: 960px) {
  .ball-col .experience-rack {
    display: flex;
  }
}
.ball-col .experience-rack > div {
  margin-bottom: 0;
  flex: 4;
}
.ball-col .experience-rack > div:first-child {
  flex: 6;
  padding-right: 2rem;
}
.experience .ball-col .icon-ball + em {
  bottom: 38px;
}
@media screen and (min-width: 960px) {
  .experience .ball-col h2.short-head, .experience .ball-col h3.short-head {
    margin-bottom: 3rem;
  }
}
.experience .ball-col h3 {
  text-transform: uppercase;
}
.experience .ball-col ul, .experience .ball-col li {
  font-size: var(--body);
}
.experience .ball-col ul {
  padding-left: 1.1rem;
}
.experience .ball-col ul, .experience .ball-col p {
  margin-bottom: 1.2rem;
}
.experience .ball-col .experience-set p:first-child {
  margin-bottom: 0.75rem;
}
.experience .ball-col .experience-set ul {
  margin-bottom: 2.5rem;
}
.experience .ball-col .experience-set li {
  line-height: 1.1;
}
.experience .ball-col .experience-set li:not(:last-child) {
  margin-bottom: 1.25rem;
}
.experience .ball-col .experience-additional-roles > p {
  margin-bottom: 0.75rem;
}
.experience .ball-col .experience-additional-roles h3 {
  margin-bottom: 1rem;
}
.experience .ball-col .experience-additional-roles:before {
  display: block;
  content: "";
  margin-bottom: 3rem;
}
.experience .ball-col .experience-additional-roles br {
  display: none;
}

.testimonial {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 960px) {
  .testimonial > p:not(.attribution) {
    margin-bottom: 1.5rem;
  }
}

.attribution {
  font-size: var(--body);
}
@media screen and (min-width: 960px) {
  .attribution {
    display: flex;
    align-items: center;
  }
}
.attribution span {
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 960px) {
  .attribution span {
    display: block;
  }
}

[data-emdash] {
  display: inline-block;
  width: 20px;
  height: 2px;
  background: var(--blue);
  margin: 0 0.5rem;
}
@media screen and (max-width: 960px) {
  .testimonial [data-emdash] {
    display: none;
  }
}

p {
  font-size: var(--body);
}

p, li {
  line-height: var(--lineHeight);
}

a {
  color: var(--blue);
}

body.mobile-open {
  overflow: hidden;
}

video {
  max-width: 100%;
}

.sub-hero-bumper {
  display: block;
  height: 5.5rem;
  margin-bottom: -5.5rem;
  background: var(--blue);
  position: relative;
  z-index: -1;
}
.sub-hero-bumper + div {
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 960px) {
  .home .sub-hero-bumper {
    display: none;
  }
}

.headline {
  font-size: var(--h1);
  padding: 0.6rem 0 3rem;
}
@media screen and (min-width: 960px) {
  .headline {
    padding: 0 0 6rem;
  }
}
.headline a {
  color: var(--white);
}
.headline span {
  line-height: 1;
  display: block;
}
.headline span:not(:last-child) {
  margin-bottom: 1.6rem;
}

.line-head {
  font-size: var(--title);
  text-transform: uppercase;
}

.home-featured-project {
  padding-bottom: 2rem;
}
@media screen and (min-width: 960px) {
  .home-featured-project {
    padding-bottom: 4rem;
  }
}
.home-featured-project + .container > .btn {
  display: block;
  width: 210px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
}
.home-featured-project--frame {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  max-width: 1000px;
  max-height: 600px;
}
.home-featured-project--frame img {
  display: block;
  filter: blur(0px);
}
.home-featured-project--overlay {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.2s ease-out;
}
.home-featured-project--overlay.overlay2 {
  padding: 1.5rem 0 2rem;
  background-color: var(--white);
  position: relative;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}
@media screen and (min-width: 960px) {
  .home-featured-project--overlay.overlay2 {
    text-align: left;
    padding: 1.5rem 4rem 2rem;
  }
}
.home-featured-project--overlay.overlay2 h2 {
  font-size: var(--h1);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.home-featured-project--overlay.overlay2 .btn.ball {
  margin-left: 0;
  margin-right: 1rem;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: none;
  /*	position: absolute;
  @include smaller-than() {
  	top: -2rem;
  	right: 0;
  	background: #fff;
  	display: none;
  }
  @include bigger-than() {
  	right: 4rem;
  	top: 52%; // calc(50% - 26px);

  }
  @include nohover() {
  	display: none;
  }*/
}
.home-featured-project--overlay.overlay2 .btn.ball span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  transition: left 0.15s ease-out;
  z-index: 2;
}
@media (hover: hover) {
  a.home-featured-project--frame:hover .home-featured-project--overlay.overlay2 .btn.ball span, .home-featured-project--overlay.overlay2 .btn.ball:hover span {
    left: 100%;
    background: var(--yellow);
  }
}
.home-featured-project--overlay.overlay2 .btn.ball::after {
  position: relative;
  top: auto;
  right: auto;
  z-index: 1;
}
.home-featured-project--frame {
  background: #7c8fee;
}
.home-featured-project--frame .home-featured-project--overlay.overlay2 {
  top: 58%;
}
@media (hover: hover) {
  .home-featured-project--frame:hover img {
    opacity: 0.6;
    transition: 0.2s all ease-out;
  }
}
@media screen and (max-width: 960px) {
  .home-featured-project, .home-featured-project + div {
    display: none;
  }
}

.featured-projects {
  margin-bottom: 3rem;
}
.projects-page .featured-projects a:not(:last-child) {
  margin-bottom: 2rem;
}
@media screen and (min-width: 960px) {
  .projects-page .featured-projects a:not(:last-child) {
    margin-bottom: 4rem;
  }
}
.featured-projects main {
  overflow-x: hidden;
  display: flex;
  position: sticky;
  top: 0;
}
.featured-projects main .featured-project {
  min-width: 100vw;
  min-height: 80vh;
}
@media screen and (min-width: 960px) {
  .featured-projects {
    margin-bottom: 8rem;
  }
}
.featured-projects > a {
  display: block;
  width: 100%;
  text-decoration: none;
}
body.projects .featured-projects > a, body.home .featured-projects > a {
  padding-bottom: 2.5rem;
}
@media (hover: hover) {
  .featured-projects > a:hover .btn.ball {
    width: 32px;
  }
  .featured-projects > a:hover .btn.ball span {
    width: 32px;
    right: 0;
  }
  .featured-projects > a:hover .btn:not(.hand).ball::after {
    background: url("../icons/arrow-right-y.svg") no-repeat 50% 50%;
  }
}
.featured-projects h2 {
  text-transform: uppercase;
  font-weight: 600;
}
.featured-projects p {
  text-transform: none;
}
.featured-projects img {
  margin-bottom: 2.4rem;
  width: 100%;
}
.featured-projects-meta {
  text-align: center;
  position: relative;
}
.featured-projects .btn.ball {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  background: var(--yellow);
}
.featured-projects .btn.ball span {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 100%;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 32px;
  background: var(--blue);
  transition: 0.15s all ease-out;
}
.featured-projects .btn.ball:before {
  display: none;
}
.featured-projects .btn.ball:not(.hand)::after {
  z-index: 1;
  background: url("../icons/arrow-right.svg") no-repeat 50% 50%;
}

.backline {
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.backline > :first-child {
  position: relative;
  z-index: 2;
  background: var(--white);
  display: inline-block;
  padding: 0 1rem;
}
@media screen and (min-width: 960px) {
  .backline > :first-child {
    padding: 0 7rem;
  }
}
.backline h2 + p {
  margin-bottom: 0.8rem;
}
.backline > hr {
  height: 5px;
  outline: none;
  background: var(--lightgrey);
  border: none;
  position: absolute;
  top: 1.75rem;
  left: 0;
  right: 0;
  width: auto;
  z-index: 0;
}
.backline.short-h > hr {
  top: 0.65rem;
}
.backline + div {
  text-align: center;
}
.backline + div > hr {
  margin: 0.75rem auto 0.65rem;
  background: var(--blue);
  border: none;
  outline: none;
  height: 2px;
  width: 16px;
}
.backline + div > hr + p {
  margin-bottom: 0.75rem;
  text-decoration: none;
}
.backline > div > .btn.ball {
  padding: 0;
}
.backline > div > .btn.ball:after {
  left: calc(50% - 16px);
}
.backline h2, .backline h3 {
  font-size: var(--title);
}

@media screen and (max-width: 960px) {
  .home-wrap-about {
    background: var(--blue);
  }
}

.about-push {
  font-size: var(--title);
  position: relative;
  margin-top: 4rem;
  margin-bottom: 8rem;
}
@media screen and (max-width: 960px) {
  .about-push {
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 3rem 2rem 3rem;
    flex-direction: column;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 960px) {
  .home .about-push {
    margin-top: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 960px) {
  .about-push.flat-top {
    padding-top: 0;
  }
  .about-push.flat-top .mask {
    display: block;
    background: var(--blue);
    top: 0;
    bottom: auto;
  }
}
@media screen and (min-width: 960px) {
  .about-push .mobile-only {
    display: none;
  }
}
@media screen and (min-width: 960px) {
  .about-push {
    padding: 4rem 4rem 0;
  }
  .about-push--content {
    padding-left: 4rem;
  }
}
.about-push.nmt {
  margin-top: 0;
}
.about-push.nmb {
  margin-bottom: 0;
}
.about-push img {
  width: 378px;
  height: auto;
  object-fit: contain;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 960px) {
  .about-push img {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.about-push h2 {
  font-size: var(--h1);
  margin-bottom: 1.75rem;
}
.about-push p {
  padding-right: 4rem;
}
.about-push p:last-child {
  margin-bottom: 3.6rem;
}
@media screen and (max-width: 960px) {
  .about-push p:last-child {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .about-push .btn {
    margin-bottom: 3.6rem;
  }
}
.about-push .cta-rack {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .about-push .cta-rack {
    justify-content: space-between;
  }
}
@media screen and (max-width: 460px) {
  .about-push .cta-rack {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 960px) {
  .about-push .cta-rack > a {
    width: calc(50% - 1rem);
  }
}
@media screen and (max-width: 960px) and (max-width: 460px) {
  .about-push .cta-rack > a {
    width: 206px;
  }
  .about-push .cta-rack > a:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.about-push .mask {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.6rem;
  z-index: 0;
  background: var(--white);
}
@media screen and (max-width: 960px) {
  .about-push .mask {
    display: none;
  }
}

.linkset {
  display: flex;
}
@media screen and (max-width: 960px) {
  .linkset {
    flex-direction: column;
  }
  .linkset .btn:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .linkset .btn:not(:last-child) {
    margin-right: 2rem;
  }
}

.about-imageset-2 img {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .about-imageset-2 {
    margin-bottom: 2rem;
  }
  .about-imageset-2 img:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .about-imageset-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem 3rem;
    margin-bottom: 2.5rem;
  }
  .about-imageset-2 img:nth-child(1) {
    grid-column: 1/2;
  }
  .about-imageset-2 img:nth-child(2) {
    grid-column: 2/3;
  }
  .about-imageset-2 img:nth-child(3) {
    grid-column: 1/3;
  }
}

.about-photo-caption {
  background: var(--lightgrey);
  padding: 16px 28px;
  max-width: 476px;
}
.project-bg .about-photo-caption {
  background: var(--white);
}
.about-photo-caption p {
  font-size: 1rem;
}

.logoset {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0 3rem;
}
@media screen and (max-width: 960px) {
  .logoset {
    justify-content: center;
  }
}
@media screen and (min-width: 960px) {
  .logoset {
    padding: 3rem 0 7rem;
  }
  .logoset img:nth-child(1),
  .logoset img:nth-child(2),
  .logoset img:nth-child(3),
  .logoset img:nth-child(4),
  .logoset img:nth-child(5) {
    flex-basis: 20%;
  }
  .logoset img:nth-child(6),
  .logoset img:nth-child(7),
  .logoset img:nth-child(8),
  .logoset img:nth-child(9) {
    flex-basis: 25%;
  }
}
.logoset img {
  padding: 30px;
  max-height: 160px;
}
@media screen and (max-width: 960px) {
  .logoset img {
    flex-basis: 33.33333%;
    max-height: 124px;
    max-width: 33.33333%;
    padding: 24px;
  }
}

.project-image {
  margin-bottom: 4rem;
}
.project-image.has-caption {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 960px) {
  .project > img.mb-4r {
    margin-bottom: 3rem;
  }
}
.project-bg {
  position: relative;
}
@media screen and (min-width: 960px) {
  .project-bg {
    padding-bottom: 4rem;
  }
}
.project .project-image-frame {
  position: relative;
}
@media screen and (max-width: 960px) {
  .project .project-image-frame {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 960px) {
  .project .project-image-frame {
    padding-bottom: 4rem;
  }
}
.project .project-image-frame:nth-child(even) .project-images-bg {
  position: absolute;
  background: var(--lightgrey);
  width: 150vw;
  top: 8rem;
  bottom: -8rem;
  left: -25vw;
  z-index: 0;
}
.project .project-image-frame:nth-child(even):last-child .project-images-bg {
  bottom: 0rem;
}
.project .project-image-frame:nth-child(odd) .about-photo-caption {
  background: var(--lightgrey);
}
.project-section {
  margin-bottom: 3.25rem;
}
.project-section > .project-image-frame > *:not(.project-images-bg) {
  position: relative;
  z-index: 1;
}
.project-section.bm-condensed {
  margin-bottom: 0rem;
}
@media screen and (min-width: 960px) {
  .project-section {
    margin-bottom: 4rem;
  }
}
.project h1 {
  width: 100%;
  background: var(--blue);
  color: var(--yellow);
  text-align: center;
  padding: 1.2rem 2rem;
  margin-bottom: 3rem;
}
@media screen and (min-width: 960px) {
  .project h1 {
    margin-bottom: 5rem;
  }
}
.project h2 {
  margin-top: 0;
}
@media screen and (max-width: 960px) {
  .experience-additional-roles h3 span {
    display: block;
    overflow: hidden;
    height: 0.0001rem;
    width: 100%;
  }
}
.experience-additional-roles h3 a {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.experience-additional-roles h3 strong {
  font-weight: 300;
}
@media screen and (max-width: 960px) {
  .experience-additional-roles h3 strong {
    display: block;
    font-size: var(--body);
  }
}

@media screen and (min-width: 960px) {
  .content {
    padding-right: 12rem;
  }
}
.content p, .content li {
  line-height: var(--lineHeight);
}

.stage-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  pointer-events: none;
}
.stage-curtain.first .curtain {
  position: absolute;
  height: 100vh;
  width: 50vw;
  top: 0;
  pointer-events: all;
  background: var(--blue) url("../images/logos/headerlogo.svg") 50% 50%/278px 224px no-repeat;
  transition: all 0.75s ease-out;
}
.stage-curtain.first .curtain.one {
  left: 0;
  background-position: calc(100% + 139px) 50%;
}
.stage-curtain.first .curtain.two {
  right: 0;
  background-position: calc(0% - 139px) 50%;
}
.stage-curtain.first.open .curtain {
  animation-duration: 1.25s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}
.stage-curtain.first.open .curtain.one {
  animation-name: curtainDesktopL;
}
.stage-curtain.first.open .curtain.two {
  animation-name: curtainDesktopR;
}
.stage-curtain:not(.first) {
  background: var(--white);
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
}
.stage-curtain:not(.first) .curtain {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}
@keyframes curtainDesktopL {
  0% {
    left: 0;
  }
  100% {
    left: -50vw;
  }
}
@keyframes curtainDesktopR {
  0% {
    right: 0;
  }
  100% {
    right: -50vw;
  }
}
.ball-box, .ball-col {
	position: relative;

	&:not(.project-section) {

		margin-bottom: 6rem;
		@include bigger-than() {
			margin-bottom: 12rem;
		}
	}
}

.ball-box {

	@include smaller-than() {
		.icon-ball {
			margin-bottom: 2rem;
		}
	}

	@include bigger-than() {
		padding: 6rem;
		border: 4px solid var(--blue);
		position: relative;

		p:not(.attribution) {
			font-size: var(--title);
			margin-bottom: 2rem;
		}

		.icon-ball {
			position: absolute;
			right: -10px;
			bottom: -16px;
			z-index: 2;
		}
		.mask {
			display: block;
			background: #fff;
			position: absolute;
			bottom: -4px;
			right: -4px;
			width: 146px;
			height: 114px;
			z-index: 1;
		}
	}
}

.ball-col {
	margin-bottom: 4rem;
	font-size: var(--title);

	@include bigger-than() {
		margin-bottom: 6rem;
	}

	&[data-mb-0] {
		@include bigger-than() {
			margin-bottom: 0;
		}
	}

	&[data-pb-0] {
		padding-bottom: 0;
	}

	@include smaller-than() {
		padding-top: 84px;
	}

	@include bigger-than() {
		padding-right: 12rem;
		padding-bottom: 6rem;

	}
	&.narrow-right {
		@include bigger-than() {
			padding-right: 4rem;
		}
	}

	h2 {
		font-size: var(--h1);
		max-width: 80%;
		margin-bottom: 2rem;
		@include bigger-than() {
			margin-bottom: 5rem;
		}
	}

	.experience-set {
		p:first-child {
			margin-bottom: 0.4rem;
		}
		ul {
			margin-bottom: 2rem;
		}
	}

	.icon-ball {
		position: absolute;
		top: 0;

		@include smaller-than() {
			left: 0;
		}

		@include bigger-than() {
			right: -52px;

			+ em, + em + em {
				position: absolute;
				background: var(--blue);
			}

			+ em {
				width: 4px;
				right: -2px;
				top: 138px;
				bottom: 88px;
			}

			+ em + em {
				height: 4px;
				left: 0;
				right: 0; // 309px;
				bottom: 0;

				&.shorter {
					right: 290px;
				}
			}
		}
	}

	>div>div:not(:last-child) {
		// margin-bottom: 5rem;
	}

	>div>div:last-child {
		// margin-bottom: 2rem;
	}

	p:first-child {
		margin-bottom: 1.5rem;
	}

	.bottom-cta {
		@include bigger-than() {
			position: absolute;
			bottom: 0;
			right: 0;
			background: #fff;
			z-index: 1;
			padding-left: 2.5rem;
		}
	}

	.experience-rack {

		@include bigger-than() {
			display: flex;
		}
		margin-bottom: 0;

		> div {
			margin-bottom: 0;
			flex: 4;

			&:first-child {
				flex: 6;
				padding-right: 2rem;
			}
		}
	}

	.experience & {

		.icon-ball + em {
			bottom: 38px;
		}

		h2.short-head, h3.short-head {
			@include bigger-than() {
				margin-bottom: 3rem;
			}
		}

		h3 {
			text-transform: uppercase;
		}

		ul, li {
			font-size: var(--body);
		}

		ul {
			padding-left: 1.1rem;
		}
			ul, p {
				margin-bottom: 1.2rem;
			}

		.experience-set {
			p:first-child {
			 	margin-bottom: 0.75rem;
			 }
			ul {
				margin-bottom: 2.5rem;
			}
			li {
				line-height: 1.1;
			}

			li:not(:last-child) {
				margin-bottom: 1.25rem;
			}
		}

		.experience-additional-roles {
			// margin-bottom: 3rem;

			> p {
				margin-bottom: 0.75rem;
			}

			h3 {
				margin-bottom: 1rem;
			}

			&:before {
				display: block;
				content: "";
				margin-bottom: 3rem;
			}

			br {
				display: none;
			}
		}
	}
}

.testimonial {
	position: relative;
	z-index: 2;
}

.testimonial > p:not(.attribution) {
	@include smaller-than() {
		margin-bottom: 1.5rem;
	}

}

	.attribution {
		font-size: var(--body);

		@include bigger-than() {
			display: flex;
			align-items: center;
		}

		span {
			text-transform: uppercase;
			font-weight: 600;
			@include smaller-than() {
				display: block;
			}
		}
	}

[data-emdash] {
	display: inline-block;
	width: 20px;
	height: 2px;
	background: var(--blue);
	margin: 0 0.5rem;

	.testimonial & {
		@include smaller-than() {
			display: none;
		}
	}
}

:root {
	--blue: #293783;
	--lightgrey: #E2E2E2;
	--darkgrey: #808080;
	--white: #FFFFFF;
	--yellow: #FFFF00;
	--lavendar: #7C8FEE;

	--h1: 1.5rem;
	--title: 1.3rem;
	--body: 1.125rem;
	--lineHeight: 1.4;

	--sideRail: 2rem;
	--maxWidth: 640px;

	@include bigger-than() {
		--h1: 3.125rem;
		--title: 1.5rem;
		--sideRail: 3rem;
		--maxWidth: 1096px;
	}
}

.bg-blue 			{
	background: var(--blue);
	color: var(--white);
}

.bg-lightgrey {
	background: var(--lightgrey);
}



@import "reset";
@import "mixins";
@import "variables";
@import "structure";
@import "frame";
@import "ui";
@import "blocks/blocks";

p {
	font-size: var(--body);
}

p, li {
	line-height: var(--lineHeight);
}

a {
	color: var(--blue);
}

body.mobile-open {
	overflow: hidden;
}

video {
	max-width: 100%;
}

.sub-hero-bumper {
	display: block;
	height: 5.5rem;
	margin-bottom: -5.5rem;
	background: var(--blue);
	position: relative;
	z-index: -1;

	+ div {
		position: relative;
		z-index: 1;
	}

	.home & {
		@include smaller-than() {
			display: none;
		}
	}
}

.headline 		{
	font-size: var(--h1);
	padding: 0.6rem 0 3rem;

	@include bigger-than() {
		padding: 0 0 6rem; // 2rem 0 6rem;
	}

	a {
		color: var(--white);
	}

	span {
		line-height: 1;
		display: block;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}

	}

}

.line-head {
	font-size: var(--title);
	text-transform: uppercase;
}

.home-featured-project {

	padding-bottom: 2rem;

	@include bigger-than() {
		padding-bottom: 4rem;
	}

	+ .container > .btn {
		display: block;
		width: 210px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 6rem;
	}

	&--frame {
		display: block;
		position: relative;
		overflow: hidden;
		text-decoration: none;
		max-width: 1000px;
		max-height: 600px;

		img {
			display: block;
			filter: blur(0px);
			// transition: 0.5s filter ease-out;
		}
	}
	&--overlay {
		position: absolute;
		left: 0;
		right: 0;
		transition: all 0.2s ease-out;
	}
//	&--overlay.overlay1 {
//		padding: 2rem 4rem;
//		bottom: -32%;
//		top: 100%;
//		height: 32%;
//		background: var(--lightgrey);
//
//
//		.fp-content {
//			h2 {
//				font-size: var(--h1);
//				position: relative;
//				z-index: 1;
//				display: inline-block;
//				padding-right: 4rem;
//				background-color: var(--lightgrey);
//				margin-bottom: 0.75rem;
//			}
//
//			.btn {
//				position: absolute;
//				right: 5rem;
//				bottom: 2.5rem;
//			}
//
//			hr {
//				background-color: var(--white);
//				border: none; outline: none;
//				height: 5px;
//				position: absolute;
//				right: 0;
//				left: 4rem;
//				top: 4.7rem;
//				z-index: 0;
//			}
//		}
//	}
//
//	&--frame:hover &--overlay.overlay1 {
//		top: 68%;
//		bottom: 0;
//	}

	&--overlay.overlay2 {
		padding: 1.5rem 0 2rem;
		 // 24.5%;
		background-color: var(--white);
		position: relative;
		width: 100%;
			text-align: center;

		@include bigger-than() {
			// height: 30%;
			text-align: left;
			padding: 1.5rem 4rem 2rem;
		}

		//@include ihover() {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%; //39%;
		//}

		@include nohover() {
		}

		h2 {
			font-size: var(--h1);
			margin-bottom: 0.5rem;
			text-transform: uppercase;
		}

		.btn.ball {
			margin-left: 0;
			margin-right: 1rem;
			padding: 0;
			position: relative;
			overflow: hidden;
			border-radius: 50%;
			border: none;

			span {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: auto;
				height: auto;
				transition: left 0.15s ease-out;
				z-index: 2;
			}

			@include ihover() {
				a.home-featured-project--frame:hover & span,
				&:hover span {
					left: 100%;
					background: var(--yellow);
				}
			}

			&::after {
				position: relative;
				top: auto;
				right: auto;
				z-index: 1;
			}
			/*	position: absolute;
			@include smaller-than() {
				top: -2rem;
				right: 0;
				background: #fff;
				display: none;
			}
			@include bigger-than() {
				right: 4rem;
				top: 52%; // calc(50% - 26px);

			}
			@include nohover() {
				display: none;
			}*/
		}
	}

	// @include ihover() {
		&--frame { //:hover {
			background: #7c8fee; // var(--white);

			img {
				// opacity: 0.4;
				// filter: blur(8px);
				// transition: 0.5s all ease-out;
			}

			.home-featured-project--overlay.overlay2 {
				top: 58%;
			}
		}
	// }

	@include ihover() {
		&--frame:hover { //:hover {

			img {
				opacity: 0.6;
				// filter: blur(1px);
				transition: 0.2s all ease-out;
				// margin-left: -2px;
				// margin-right: -2px;
				// margin-top: -1px;
				// width: calc(100% + 4px);
				// max-width: calc(100% + 4px);
			}
		}
	}

	@include smaller-than() {
		&, & + div {
			display: none;
		}
	}
}

.featured-projects {
	// position: relative;

	@include bigger-than() {
		// height: 100vh;
	}

	.projects-page & {
		a:not(:last-child) {
			margin-bottom: 2rem;
			@include bigger-than() {
				margin-bottom: 4rem;
			}
		}
	}

	main {
		overflow-x: hidden;
		display: flex;
		position: sticky;
		top: 0;
		// background: orange;

		.featured-project {
			min-width: 100vw;
			min-height: 80vh;
		}
	}

	margin-bottom: 3rem;
	// text-transform: uppercase;

	@include bigger-than() {
		margin-bottom: 8rem;
	}

	> div > a:not(:first-child) {
		// display: none;
	}

	> a {
		display: block;
		width: 100%;
		text-decoration: none;

		body.projects &,
		body.home & {
			// border-bottom: 1px solid red;
			padding-bottom: 2.5rem;
			// margin-bottom: 7rem;
		}
		// margin-bottom: 7rem;

		@include ihover() {
			&:hover {

				.btn.ball {
					width: 32px;

					span {
						width: 32px;
						right: 0;
					}
				}
				.btn:not(.hand).ball::after {
					// background: none;
					background: url("../icons/arrow-right-y.svg") no-repeat 50% 50%;
				}
			}
		}
	}

	h2 {
		text-transform: uppercase;
		font-weight: 600;
	}

	p {
		text-transform: none;
	}

	img {
		margin-bottom: 2.4rem;
		width: 100%;
	}

	&-meta {
		text-align: center;
		position: relative;
	}

	&-content {
		// position: relative;
		// z-index: 2;
		// background: var(--white);
		// display: inline-block;
		// padding: 0 7rem;
	}

	.btn.ball {
		display: block;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		position: relative;
		overflow: hidden;
		margin-left: auto; margin-right: auto;
		background: var(--yellow);

		span {
			position: absolute;
			z-index: 0;
			left: 0;
			right: 100%;
			top: 0;
			left: 0;
			display: block;
			width: 0;
			height: 32px;
			background: var(--blue);
			transition: 0.15s all ease-out;
		}



		&:before {
			display: none;
		}
		&:not(.hand)::after {
			// background: none;
			z-index: 1;
			background: url("../icons/arrow-right.svg") no-repeat 50% 50%;
		}
	}
}

.backline {

	text-align: center;
	text-transform: uppercase;
	position: relative;

	> :first-child {
		position: relative;
		z-index: 2;
		background: var(--white);
		display: inline-block;
		padding: 0 1rem;

		@include bigger-than() {
			padding: 0 7rem;
		}
	}

	h2 + p {
		margin-bottom: 0.8rem;
	}

	> hr {
		height: 5px;
		outline: none;
		background: var(--lightgrey);
		border: none;
		position: absolute;
		top: 1.75rem; //calc(50% - 12.5px);
		left: 0;
		right: 0;
		width: auto;
		z-index: 0;
	}

	&.short-h > hr {
		top: 0.65rem; // calc(50% - 12.5px);
	}

	+ div {
		text-align: center;

			> hr {
				margin: 0.75rem auto 0.65rem;
				background: var(--blue);
				border: none;
				outline: none;
				height: 2px;
				width: 16px;

				+ p {
					margin-bottom: 0.75rem;
					text-decoration: none;
				}
			}
	}
	> div > .btn.ball {
		padding: 0;

		&:after {
			left: calc(50% - 16px);
		}
	}

	h2, h3 {
		font-size: var(--title);
	}
}

.home-wrap-about {
	@include smaller-than() {
		background: var(--blue);
	}
}

.about-push {

	font-size: var(--title);
	position: relative;
	margin-top: 4rem;
	margin-bottom: 8rem;

	@include smaller-than() {
		margin-left: -2rem;
		margin-right: -2rem;
		padding: 3rem 2rem 3rem;
		flex-direction: column;
		margin-bottom: 3rem;
	}

	.home & {
 		@include smaller-than() {
 			margin-top: 0;
 			padding-top: 0;
 		}
	}

	&.flat-top {
		@include smaller-than() {
			padding-top: 0;

			.mask {
				display: block;
				background: var(--blue);
				top: 0;
				bottom: auto;
			}
		}
	}

	.mobile-only {
		@include bigger-than() {
			display: none;
		}
	}

	@include bigger-than() {
		padding: 4rem 4rem 0;

		&--content {
			padding-left: 4rem;
		}
	}

	&.nmt { margin-top: 0; }
	&.nmb { margin-bottom: 0; }

	img {
		width: 378px;
		height: auto; // 570px;
		object-fit: contain;
		position: relative;
		z-index: 1;
		@include smaller-than() {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	h2 {
		font-size: var(--h1);
		margin-bottom: 1.75rem;
	}

	p {
		padding-right: 4rem;

		&:last-child {
			margin-bottom: 3.6rem;
			@include smaller-than() {
				margin-bottom: 2rem;
			}
		}
	}

	.btn {
		@include bigger-than() {
			margin-bottom: 3.6rem;
		}
	}

	.cta-rack {
		width: 100%;
			@include smaller-than() {
				justify-content: space-between;
			}

				@include smaller-than(460px) {
					flex-wrap: wrap;
					// flex-direction: column;
				}

		> a {
			@include smaller-than() {
				width: calc(50% - 1rem);

				@include smaller-than(460px) {
					width: 206px;

					&:not(:last-child) {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}

	.mask {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 3.6rem;
		z-index: 0;
		background: var(--white);

		@include smaller-than() {
			display: none;
		}
	}
}

.linkset {
	display: flex;

	@include smaller-than() {
		flex-direction: column;

		.btn:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	@include bigger-than() {
		.btn:not(:last-child) {
			margin-right: 2rem;
		}
	}
}

.about-imageset-2 {

	img {
		width: 100%;
	}

	@include smaller-than() {
		margin-bottom: 2rem;
		img:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	@include bigger-than() {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2.5rem 3rem;
		margin-bottom: 2.5rem;

		img:nth-child(1) {
			grid-column: 1 / 2;
		}

		img:nth-child(2) {
			grid-column: 2 / 3;
		}

		img:nth-child(3) {
			grid-column: 1 / 3;
		}
	}
}

.about-photo-caption {
	background: var(--lightgrey);
  padding: 16px 28px;
  max-width: 476px;

  .project-bg & {
  	background: var(--white);
  }

	p {
		font-size: 1rem;
	}
}



.logoset {
	display: flex;
	flex-wrap: wrap;
	padding: 1rem 0 3rem;
	//width: calc(100% + 140px);
	//margin-left: -70px;
	//margin-right: -70px;
	@include smaller-than() {
		justify-content: center;
	}
	@include bigger-than() {
		padding: 3rem 0 7rem;

		img:nth-child(1),
		img:nth-child(2),
		img:nth-child(3),
		img:nth-child(4),
		img:nth-child(5) {
			flex-basis: 20%;
		}


		img:nth-child(6),
		img:nth-child(7),
		img:nth-child(8),
		img:nth-child(9) {
			flex-basis: 25%;
		}
	}

	img {
		padding: 30px;
		max-height: 160px;

		@include smaller-than() {
			flex-basis: 33.33333%;
			max-height: 124px;
			max-width: 33.33333%;
			padding: 24px;
		}
	}
}

.project {

	&-image {
		margin-bottom: 4rem;

		&.has-caption {
			margin-bottom: 2.5rem;
		}
	}

	> img.mb-4r {
		@include smaller-than() {
			margin-bottom: 3rem;
		}
	}

	&-bg {
		position: relative;

		@include bigger-than() {
			padding-bottom: 4rem;
		}

		.about-photo-caption.mb-4r {
			// margin-bottom: 0;
		}
	}

	.image-set {
		// overflow: hidden;
	}

	.project-image-frame {
		position: relative;
		// overflow: hidden;

		@include smaller-than() {
			padding-bottom: 1rem;
		}

		@include bigger-than() {
			padding-bottom: 4rem;
		}

	}

	.project-image-frame:nth-child(even) {
		.project-images-bg {
			position: absolute;
			background: var(--lightgrey);
			width: 150vw;
			top: 8rem; bottom: -8rem;
			left: -25vw;
			z-index: 0;
		}

		&:last-child .project-images-bg {
			bottom: 0rem;
		}
	}

	.project-image-frame:nth-child(odd) {
		.about-photo-caption {
			background: var(--lightgrey);
		}
	}

	&-section {
		> .project-image-frame > *:not(.project-images-bg) {
			position: relative;
			z-index: 1;
		}
		margin-bottom: 3.25rem;

		&.bm-condensed {
			margin-bottom: 0rem;
		}

		@include bigger-than() {
			margin-bottom: 4rem;
		}

		&.featured-video {
			video {
				@include smaller-than() {
					// margin: -1.5rem 0;
				}
			}
		}
	}

	h1 {
		width: 100%;
		background: var(--blue);
		color: var(--yellow);
		text-align: center;
		padding: 1.2rem 2rem;
		margin-bottom: 3rem;

		@include bigger-than() {
			margin-bottom: 5rem;
		}
	}

	h2 {
		margin-top: 0;
	}

	.testimonial {

	}
}

.experience {
	&-additional-roles {
		h3 span {
			@include smaller-than() {
				display: block;
				overflow: hidden;
				height: 0.0001rem;
				width: 100%;
			}
		}
		h3 a {
			display: inline-block;
			margin-bottom: 0.5rem;
		}
		h3 strong {
			font-weight: 300;
			@include smaller-than() {
				display: block;
				font-size: var(--body);
			}
		}
	}
}

.content {
		@include bigger-than() {
			padding-right: 12rem;
		}
	p, li {
		line-height: var(--lineHeight);
	}
}

.stage-curtain {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	//background: var(--blue);
	z-index: 9999;
	pointer-events: none;

	&.first {
		.curtain {
			position: absolute;
			height: 100vh;
			width: 50vw;
			top: 0;
			pointer-events: all;
			background: var(--blue) url('../images/logos/headerlogo.svg') 50% 50% / 278px 224px no-repeat;
			transition: all 0.75s ease-out;
			&.one {
				left: 0; // -50vw;
				background-position: calc(100% + 139px) 50%;
			}
			&.two {
				right:0; // -50vw;
				background-position: calc(0% - 139px) 50%;
			}
		}

		&.open {
			.curtain {
				animation-duration: 1.25s;
				animation-iteration-count: 1;
				animation-timing-function: ease-in;
				animation-delay: 0.75s;
				animation-fill-mode: forwards;

				&.one {
					animation-name: curtainDesktopL;
				}
				&.two {
					animation-name: curtainDesktopR;
				}
			}
		}
	}

	&:not(.first) {
		background: var(--white);
		animation-duration: 0.5s;
		animation-iteration-count: 1;
		animation-timing-function: ease-in-out;
		animation-delay: 0.25s;
		animation-fill-mode: forwards;
		animation-name: fadeIn;
		.curtain {
			display: none;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 100%;
	}
	100% {
		opacity: 0;
	}
}

@keyframes curtainDesktopL {
	0% {
		left: 0;
	}
	100% {
		left: -50vw;
	}
}

@keyframes curtainDesktopR {
	0% {
		right: 0;
	}
	100% {
		right: -50vw;
	}
}



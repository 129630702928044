header {
	padding: 1.5rem 0 1.5rem;
	background: var(--blue);
	color: var(--white);

	@include bigger-than() {
		padding: 2.5rem 0;
	}

	// .mobile-open & {

	@include smaller-than() {
		position: relative; // // fixed;
		top: 0;
		width: 100%;
		z-index: 3;

		// + * + * {
		// 	display: block;
		// 	content: "";
		// 	padding-top: calc(96px + 2.5rem);
		// }
	}

	.header-nav 	{
		display: inline-block;
		a 	{
			text-decoration: none;
			font-weight: 400;
			color: var(--white);
			font-size: var(--body);
			-webkit-tap-highlight-color: transparent; // for removing the highlight
			position: relative;

			&:before {
					@include bigger-than() {
						display: block;
						content: "";
						background: var(--white);
						height: 4px;
						width: auto;
						position: absolute;
						bottom: -0.8rem;
						left: 50%;
						right: 50%;
						transition: all 0.1s ease-out;
					}
			}
			&:hover:before {
				left: 0;
				right: 0;
			}

			body.about &.about,
			body.projects &.projects {
				color: var(--yellow);
				position: relative;

				&:before {
					@include bigger-than() {
						display: block;
						content: "";
						background: var(--yellow);
						height: 4px;
						width: auto;
						position: absolute;
						bottom: -0.8rem;
						left: 0;
						right: 0;
					}
				}
				//text-decoration: underline;
			}
		}

		@include bigger-than() {
			a:not(:last-child) 	{
				margin-right: 3rem;
			}
		}

		@include smaller-than() {
			top: 0;
			display: flex;
			position: fixed;
			right: -100vw;
			width: 100%;
			height: 100%;
			background: var(--blue);
			z-index: 2000;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: all 0.2s ease-in-out;
			overflow: hidden;

			a {
				// color: var(--blue);
				font-size: var(--title);
				padding: 1rem;

				&:not(:last-child) {
					// margin-bottom: 2rem;
				}
			}

			.mobile-mask {
				position: absolute;
				top: 0;
				height: 96px;
				width: 100%;
				left: 0;
				background-color: var(--yellow);
				overflow: hidden;
				z-index: 2002;
				clip-path: inset(0);

				img {
					position: fixed;
					width: 60px;
					top: 24px;
					left: 32px;
				}
			}

			.mobile-open & {
				right: 0;
			}
		}
	}

	.header-logo {
		position: relative;
		// z-index: 2001;
		@include smaller-than() {
			width: 60px;
		}
	}

	.mobile-menu {
		width: 72px;
		height: 72px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		border: 0px solid white;
		background: transparent;
		margin-right: -24px;
		margin-top: -12px;
		cursor: pointer;
		z-index: 2002;
		position: relative;
		border: 0px solid yellow;
		-webkit-tap-highlight-color: transparent; // for removing the highlight

		@include bigger-than() {
			display: none;
		}

		span {
			background: var(--white);
			width: 24px;
			height: 2px;
			position: absolute;
			transition: all 0.2s ease-in-out;

			// &:nth-child(1),
			&:nth-child(1) {
				top: 28px;
			}
			&:nth-child(2) {

			}
			&:nth-child(3) {
				bottom: 28px;
			}
			&:nth-child(4),
			&:nth-child(5) {
				width: 0;

			}
			&:nth-child(4) {
				transform: rotate(-45deg);
			}
			&:nth-child(5) {
				transform: rotate(45deg);
			}
		}

		.mobile-open & {
			span {
				background: var(--blue);
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					width: 0px;
				}
				&:nth-child(4),
				&:nth-child(5) {
					width: 24px;
				}
			}
		}
	}
}

footer 		{
	padding: 1.5rem 0;
	background: var(--lightgrey);
	color: var(--blue);
	text-align: center;
	position: relative;

	.footer-verbiage {
		padding: 0.7rem 0 2rem;
		position: relative;

		@include bigger-than() {
			padding: 1.125rem 0 2rem;
		}
	}

	.footer-verbiage > p 	{
		font-size: var(--title);
	}

	.footer-logo 	{

		@include smaller-than() {
			width: 100px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 2rem;
		}

		@include bigger-than() {
			position: absolute;
			top: 0;
			left: -30px;
		}
	}

	.footer-cta 	{
		padding-bottom: 2.4rem;
	}

	.bottom-line 	{
		padding-top: 1.5rem;
		border-top: 4px solid var(--white);
		font-size: 0.9rem;
		text-align: left;
		display: flex;
		justify-content: space-between;

		@include smaller-than() {
			text-align: center;
			flex-direction: column;
		}

		a {
			text-decoration: none;
			position: relative;

			&:before {
				display: block;
				content: "";
				height: 100%;
				position: absolute;
				width: 0%;
				top: 0;
				right: 0;
				background: var(--yellow);
				transition: width 0.15s ease-out;
				z-index: 0;
			}

				span {
					display: inline-block;
					position: relative;
					z-index: 1;
				}
			&:hover:before {
				width: 100%;
			}
		}
	}
}



.df 		{	display: flex;	}
.db 		{	display: block;	}
.fc 		{	flex-direction: column;	}
.ec > div
				{	flex: 1;	}

.jc			{	justify-content: center;	}
.ja			{	justify-content: space-around;	}
.jb			{	justify-content: space-between;	}
.js 		{	justify-content: flex-start;	}
.je			{	justify-content: flex-end;	}

.w100 	{	width: 100%;	}

.ac 		{	align-items: center;	}
.as 		{	align-items: flex-start;	}
.ae 		{	align-items: flex-end;	}

.cp 		{	cursor: pointer;	}

.container 		{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: var(--maxWidth);
	padding-left: var(--sideRail);
	padding-right: var(--sideRail);
}

.height-minus-frame {
	min-height: calc(100vh - 490px);
}

.mb-2r 	{ margin-bottom: 2rem; }
.mb-3r 	{ margin-bottom: 3rem; }
.mb-4r 	{ margin-bottom: 4rem; }
.mb-5r 	{ margin-bottom: 5rem; }
.mb-6r 	{ margin-bottom: 6rem; }
.mb-8r 	{ margin-bottom: 8rem; }
.mb-9r 	{ margin-bottom: 9rem; }

.pt-2r	{	padding-top: 2rem;	}
.pt-3r	{	padding-top: 3rem;	}
.pt-4r	{	padding-top: 4rem;	}

.vert-spacer {
	margin-bottom: 3rem;

	@include smaller-than() {
		&.mobile-tall {
			margin-bottom: 5rem;
		}
	}

	@include bigger-than() {
		margin-bottom: 6rem;
	}

	&.taller {
		margin-bottom: 4rem;

		@include bigger-than() {
			margin-bottom: 9rem;
		}
	}
}

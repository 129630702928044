.btn {
	display: inline-flex;
	border: 2px solid var(--blue);
	color: var(--blue);
	padding: 0.8rem 3rem 0.8rem 1.2rem;
	border-radius: .15rem;
	text-decoration: none;
	font-size: var(--body);
	// background: url("../icons/arrow-right.svg") no-repeat calc(100% - 1rem) 50%;
	position: relative;
	-webkit-tap-highlight-color: transparent; // for removing the highlight
	z-index: 1;

	&.dark.hand {
		background: var(--blue);
		padding-right: 3.4rem;

		&:after {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url("../icons/hand.svg") no-repeat calc(100% - 0.4rem) calc(50% - 2px);

		}

		&:hover {
			@include ihover() { @include bigger-than() {
				color: var(--blue);
				&:after {
					background-image: url("../icons/hand-rev.svg");
					background-position: 100% calc(50% - 2px);
					background-size: contain;
					transition: all 0.75s ease-out;
				}
			} }
		}
	}

	&:not(.hand):after {
		display: block;
		position: absolute;
		top: calc(50% - 8px);
		right: 1rem;
		content: "";
		width: 18px;
		height: 16px;
		background: url("../icons/arrow-right.svg") no-repeat 50% 50%;
	}

	&:before {
		display: block;
		position: absolute;
		top: 50%; left: 0; right: 100%; bottom: 50%;
		content: "";
		width: auto;
		height: auto;
		background: var(--yellow);
		z-index: -1;
		transition: all 0.2s ease-in-out;
	}

	@include ihover() { @include bigger-than() {
		&:not([data-no-rollover], .ball):hover:before {
			right: 0;
			top: 0;
			bottom: 0;
		}
	} }

	@media (hover: none) {
		&:not([data-no-rollover], .ball):before {
			// right: 0;
			// top: 0;
			// bottom: 0;
		}
	}



	[data-horz-ctas] &, .about-push [data-horz-ctas] & {

			@include smaller-than() {
				margin-bottom: 0;
			}

		&:not(:last-child) {
			margin-right: 1rem;
			@include smaller-than() {
				margin-bottom: 2rem;
			}
		}
	}

	&.dark {
		background-color: var(--blue);
		color: var(--yellow);
		background: var(--blue); //  url("../icons/arrow-right-w.svg") no-repeat calc(100% - 1rem) 50%;

	}

	&.rev {
		border-color: var(--white);
		color: var(--white);
		background: url("../icons/arrow-right-w.svg") no-repeat calc(100% - 1rem) 50%;
		&:after {

			background: url("../icons/arrow-right-w.svg") no-repeat 50% 50%;
		}
		&:hover {
			@include ihover() { @include bigger-than() {
				border-color: var(--yellow);
				color: var(--blue);

				&:after {
					background: url("../icons/arrow-right.svg") no-repeat 50% 50%;
				}
			} }
		}
	}

	&.ball {
		border: none;
		background: transparent;

		@include ihover() {
					span {
						width: 32px;
						right: 0;
					}
		}

		&:after {
			background-color: var(--yellow);
			width: 32px;
			height: 32px;
			border-radius: 50%;
			top: calc(50% - 16px);
			right: 0;
		}

		a.home-featured-project--frame:hover &:after,
		&:hover:after {
			@include ihover() { @include bigger-than() {
					// .featured-projects > a:hover &:after {
					background: var(--blue) url("../icons/arrow-right-y.svg") no-repeat 50% 50%;
				}
			}
		}
	}
}

.icon-ball {
		width: 52px;
		height: 52px;
		background: var(--blue);
		border-radius: 50%;

		&.yellow.quote {
			background: var(--yellow) url('../icons/quote-b.svg') 50% 50% no-repeat; background-size: 57px 40px;
			@include smaller-than() {
				background-size: 28.5px 20px;
			}
		}

		&.quote {
			background: var(--blue) url('../icons/quote.svg') 50% 50% no-repeat; background-size: 57px 40px;
			@include smaller-than() {
				background-size: 28.5px 20px;
			}
		}

		&.list {
			background: var(--blue) url('../icons/list.svg') 50% 50% no-repeat; background-size: contain;
		}

		&.tools {
			background: var(--blue) url('../icons/tools.svg') 50% 50% no-repeat; background-size: contain;
		}

		@include smaller-than() {
			background-size: 50% 50%;
		}

		@include bigger-than() {
			width: 104px;
			height: 104px;
		}
}

.line-head {
	margin-bottom: 2rem;
	position: relative;

		@include bigger-than() {
			margin-bottom: 4rem;
		}
		&.compact {
			@include bigger-than() {
				margin-bottom: 2rem;
			}
			+ .project-section {
				// margin-bottom: 4rem;

				// @include bigger-than() {
					margin-bottom: 6rem;
				// }
			}

			&.bm-condensed + .project-section {
				margin-bottom: 4rem;
			}
		}

	span {
		background: var(--white);
		padding-right: 2rem;
		position: relative;
		display: inline-block;
		width: auto;
		white-space: collapse;
	}

	&.right {
		text-align: right;

		span {
			padding-left: 2rem;
			padding-right: 0;
		}
	}

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 4px;
		background: var(--lightgrey);
		position: absolute;
		top: calc(50% - 2px);
	}

	&.dark:before {
		background: var(--blue);
	}
}
